<template>
  <div class="select-container-wrapper">
    <div class="select-container">
      <div class="select-box">
        <template v-if="expanded">
          <div class="options-container active">
            <div v-if="!withSubList" class="headerList">
              <span>{{ title || 'Titulo' }} </span>
              <span>Descrição</span>
            </div>
            <div
              :key="favorite.id"
              v-for="favorite in favorites"
              class="option"
              @click="withSubList ? null : onFavoriteChosen(favorite)"
            >
              <template v-if="withSubList">
                <div class="flex">
                  <input
                    type="radio"
                    name="favorite"
                    class="radio"
                    :id="favorite[titleProperty]"
                  />
                  <label class="option__text" :for="favorite[titleProperty]"
                    >{{ favorite[titleProperty] }}
                  </label>
                  <ul class="option__sub-options">
                    <li @click="onFavoriteChosen({ grau: 0, ...favorite })">
                      Leve
                    </li>
                    <li @click="onFavoriteChosen({ grau: 1, ...favorite })">
                      Moderada
                    </li>
                    <li @click="onFavoriteChosen({ grau: 2, ...favorite })">
                      Severa
                    </li>
                  </ul>
                </div>
              </template>
              <template v-else>
                <div class="optionData">
                  <span :for="favorite[titleProperty]"
                    >{{ favorite[titleProperty] }}
                  </span>
                  <span
                    :title="
                      verifyIfStringHaveATag(favorite[descriptionProperty])
                    "
                    class="overflowText"
                    v-html="favorite[descriptionProperty]"
                  ></span>
                </div>
              </template>
            </div>
          </div>
        </template>
        <div class="selected" @click="toggleExpanded()">
          <p style="color: #000 !important">{{ selectedFavorite }}</p>
          <b-icon
            class="selected__icon"
            icon="arrow-down-circle"
            style="color: #089bab !important"
            font-scale="2"
          ></b-icon>
        </div>
      </div>
    </div>
    <div
      @click="hideFavoritesSelect"
      v-if="expanded"
      style="
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
      "
    ></div>
  </div>
</template>

<script>
export default {
  name: 'FavoritesSelect',
  props: [
    'favorites',
    'titleProperty',
    'withSubList',
    'descriptionProperty',
    'title'
  ],
  data() {
    return {
      expanded: false,
      selectedFavorite: 'Favoritos...'
    }
  },

  methods: {
    verifyIfStringHaveATag(string) {
      const tagOpened = string.indexOf('>')
      const tagClosed = string.indexOf('</')

      if (tagOpened !== -1 && tagClosed !== -1) {
        return this.removeTagFromString(string, tagOpened, tagClosed)
      } else {
        return string
      }
    },
    removeTagFromString(string, start, end) {
      const substring = string.substring(start + 1, end)
      return substring
    },

    hideFavoritesSelect() {
      this.expanded = false
    },
    toggleExpanded() {
      this.expanded = !this.expanded
    },

    onFavoriteChosen(data) {
      this.expanded = false
      this.$emit('on-favorite-chosen', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-container-wrapper {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.headerList {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 30px;
  background: #089bab;
  color: #fff;
  font-weight: bold;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
}

.optionData {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 10px 0px 10px 10px;
  justify-content: center;
  align-items: center;
}

.select-box {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  width: 400px;

  .selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    position: relative;
    order: 0;
    padding: 5px 16px;

    background-color: white;
    border: 1px solid rgb(8, 155, 171) !important;

    p {
      padding: 0;
      color: #000;

      margin: 0;
    }
  }

  .options-container {
    background-color: white;
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 105%;
    max-height: 0;
    opacity: 0;
    transition: all 0.4s;
    border-radius: 8px;
    overflow: hidden;
    color: #000;
    order: 1;
    border: 1px solid #000;

    &.active {
      max-height: 300px;
      opacity: 1;
      overflow-y: scroll;
    }
  }

  .option {
    color: #000;
    border-bottom: 1px solid #ccc;
    .radio {
      display: none;
    }

    &__text {
      display: flex;
      color: #000 !important;
      cursor: default;
      flex-basis: 30%;
    }

    &__sub-options {
      display: flex;
      flex: 1;
      margin: 5px;
      justify-content: space-evenly;
      align-items: center;
      padding: 0;
      cursor: default;

      li {
        cursor: pointer;
        margin: 0 4px;
        width: max-content;
        border-radius: 10px;
        text-align: center;
        padding: 8px;
      }

      li:hover {
        transform: scale(1.1);
      }

      & > li:nth-child(1) {
        border-radius: 10px;
        background-color: #abebc6;
      }
      & > li:nth-child(2) {
        border-radius: 10px;
        background-color: #f9e79f;
      }
      & > li:nth-child(3) {
        border-radius: 10px;
        background-color: #f5b7b1;
      }
    }
  }

  .option:nth-child(odd) {
    background-color: #e6e6e6;
  }

  .option:hover {
    z-index: 5;
    border: 1px solid #ccc;
    border-radius: 2px;
  }
}

.overflowText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 850px) {
  .select-container-wrapper {
    display: none;
  }
}
</style>
