<template>
  <div class="table-container">
    <b-table-simple hover>
      <b-thead>
        <b-tr>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header data"
          >
            Data
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Profissional
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Medicamento/Material
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Qtd.
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Posologia
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          >
            Medicamento em Uso
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header aderencia"
          >
            Aderência
          </b-th>
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header observ"
          >
            Observação
          </b-th>
          <!-- <th :style="`background-color: ${headerColor}`" class="table-header">
            Link
          </th> -->
          <b-th
            :style="`background-color: ${headerColor}`"
            class="table-header"
          ></b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="prescriptions">
        <b-tr
          :style="
            prescription.usoContinuo === 1
              ? 'background-color: #fffddd;'
              : 'background-color: #fff'
          "
          v-for="prescription in prescriptionsToShow"
          :key="prescription.id"
          @click="openLinkTooltip($event, prescription)"
        >
          <b-td
            style="cursor: pointer"
            :class="prescription.ativo === 1 ? 'line-throughed' : ''"
          >
            {{ handleFformatLocalDateHour(prescription.dtInclusao) }}
          </b-td>

          <b-td
            style="cursor: pointer"
            :class="prescription.ativo === 1 ? 'line-throughed' : ''"
          >
            {{ prescription.nmProfissional }}
          </b-td>

          <b-td
            style="cursor: pointer"
            :class="prescription.ativo === 1 ? 'line-throughed' : ''"
          >
            {{ prescription.desMedicamento }}
          </b-td>

          <b-td
            style="cursor: pointer"
            :class="prescription.ativo === 1 ? 'line-throughed' : ''"
          >
            {{ prescription.quantidade }}
          </b-td>

          <b-td
            style="cursor: pointer"
            :class="prescription.ativo === 1 ? 'line-throughed' : ''"
          >
            {{ stripHtmlTags(prescription.posologia) }}
          </b-td>

          <b-td class="remdioUso">
            <input
              type="checkbox"
              name="remedioUse"
              class="remedioUse"
              @change="editPrescriptionMedicineUse($event, prescription)"
              :checked="
                prescription.usoContinuo === null ||
                prescription.usoContinuo === 0
                  ? false
                  : true
              "
            />
          </b-td>

          <b-td class="aderencia">
            <v-select
              class="v-select"
              @input="editPrescriptionAdherence($event, prescription)"
              :value="prescription.idTipoAderencia"
              :options="adherenceOptions"
              :reduce="(option) => option.id"
              :clearable="false"
              label="text"
              append-to-body
            />
          </b-td>

          <b-td class="observ">
            <b-button
              class="button-pdf"
              variant="outline-light"
              @click="showObservationModal(prescription)"
            >
              <i
                :class="
                  tablet ? 'fas fa-file-alt fa-1x' : 'fas fa-file-alt fa-2x'
                "
                style="color: #089bab"
              ></i>
            </b-button>
          </b-td>

          <!-- <td>
            <b-button variant="outline-light" title="Copiar link de prescrição">
              <i class="fas fa-solid fa-copy fa-2x" style="color: #089bab"></i>
            </b-button>
          </td> -->

          <b-td>
            <b-button
              class="removeAndAtivar"
              :variant="prescription.ativo === 0 ? 'danger' : 'primary'"
              @click="
                prescription.ativo === 0
                  ? removerOrDisablePrescription(prescription)
                  : enablePrescription(prescription)
              "
            >
              {{
                prescription.ativo === 0
                  ? definePrescriptionAction(prescription)
                  : 'Ativar'
              }}
            </b-button>
          </b-td>

          <b-modal
            :id="`prescription_justification_${prescription.id}`"
            size="lg"
            title="Justificativa"
            header-bg-variant="primary"
            title-class="text-light"
            label-for="justify-input"
            ok-title="Salvar"
          >
            <b-form-group label-for="justify-input">
              <ValidationProvider
                name="justify"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  id="justify-input"
                  maxlength="150"
                  v-model="justify"
                  :class="
                    errors.length > 0 || justifyNotValid ? ' is-invalid' : ''
                  "
                ></b-form-textarea>
                <div class="invalid-feedback" :v-if="justifyNotValid">
                  <span>A justificativa deve conter no mínimo 5 digitos!</span>
                </div>
              </ValidationProvider>
            </b-form-group>

            <p>{{ justify.length }}/150</p>
            <template #modal-footer="{ cancel }">
              <b-button size="sm" variant="danger" @click="cancel()">
                Cancelar
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                @click="disablePrescription(prescription)"
              >
                Salvar
              </b-button>
            </template>
          </b-modal>

          <b-modal
            :id="`prescription_observation_${prescription.id}`"
            size="lg"
            title="Observação"
            header-bg-variant="primary"
            title-class="text-light"
            label-for="observation-input"
            ok-title="Salvar"
          >
            <b-form-textarea
              id="observation-input"
              ref="observationInput"
              maxlength="150"
              v-model="observation"
              autofocus
            />

            <template #modal-footer="">
              <b-button
                size="sm"
                variant="danger"
                @click="hideObservationModal(prescription.id)"
              >
                Cancelar
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                @click="editPrescriptionObservation(prescription)"
              >
                Salvar
              </b-button>
            </template>
          </b-modal>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <prescription-link-tooltip
      :positionX="linkTooltipPositionX"
      :positionY="linkTooltipPositionY"
      :link="prescriptionLink"
      v-if="linkTooltipOpen"
      @mouseleave="linkTooltipOpen = false"
    />

    <remove-prescriptions-modal
      :prescriptions-to-remove="prescriptionsToRemove"
      @removePrescriptions="removePrescriptions"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  formatLocalDateHour,
  removeTagsFromString
} from '../../../../../Utils/formatters'
import RemovePrescriptionsModal from './removePrescriptionsModal'
import PrescriptionLinkTooltip from './prescriptionLinkTooltip'
export default {
  name: 'PrescriptionsList',
  components: {
    RemovePrescriptionsModal,
    PrescriptionLinkTooltip
  },
  props: [
    'headerColor',
    'prescriptions',
    'toggleMedicineUse',
    'showInactivePrescriptions',
    'idProntuario',
    'token'
  ],
  data() {
    return {
      linkTooltipPositionX: '',
      linkTooltipPositionY: '',
      prescriptionLink: '',
      linkTooltipOpen: false,
      idPrescriptionToRemove: '',
      justify: '',
      observation: '',
      adherenceOptions: [
        {
          id: 1,
          text: 'Completo'
        },
        {
          id: 2,
          text: 'Não aderiu'
        },
        {
          id: 3,
          text: 'Parcial'
        }
      ]
    }
  },
  watch: {
    prescriptions: {
      handler: function (newValue, oldValue) {
        if (
          oldValue &&
          JSON.stringify(newValue) !== this.prescriptionsInitivalValue
        ) {
          this.$store.commit('MedicalReports/changeModulesStateChangedToTrue')
        }
      },
      deep: true
    }
  },
  computed: {
    prescriptionsToShow() {
      let items

      if (this.showInactivePrescriptions) {
        items = this.prescriptions
      } else {
        if (this.toggleMedicineUse) {
          items = this.prescriptions.filter((element) => {
            return element.usoContinuo === 1
          })
        } else {
          items = this.prescriptions.filter((element) => {
            return element.ativo === 0
          })
        }
      }

      return items
    },

    tablet() {
      if (window.innerWidth <= 727) {
        return true
      } else {
        return false
      }
    },

    prescriptionsToRemove() {
      const items = this.prescriptions.filter((element) => {
        return element.idPrescricaoMemed === this.idPrescriptionToRemove
      })

      return items
    },
    justifyNotValid() {
      return this.justify.length < 5
    }
  },
  methods: {
    ...mapActions({
      getPrescriptionHistoryAction:
        'MedicalReports/getPrescriptionHistoryAction',
      disablePrescriptionAction: 'MedicalReports/disablePrescriptionAction',
      enablePrescriptionAction: 'MedicalReports/enablePrescriptionAction',
      editPrescriptionAction: 'MedicalReports/editPrescriptionAction',
      removePrescriptionAction: 'MedicalReports/removePrescriptionFromHandbook'
    }),
    openLinkTooltip(event, prescription) {
      if (event.target.tagName === 'TD' && !event.target.children[0]) {
        this.linkTooltipPositionX = event.pageX
        this.linkTooltipPositionY = event.pageY
        this.prescriptionLink = prescription.linkMemedPaciente
        this.linkTooltipOpen = true
      }
    },
    getInitialPrescriptionValue() {
      this.prescriptionsInitivalValue = JSON.stringify(this.prescriptions)
    },
    filterAdherenceItem(id) {
      const item = this.adherenceOptions.filter((element) => {
        return element.id === id
      })

      return item
    },

    handleFformatLocalDateHour(date) {
      return formatLocalDateHour(date)
    },
    removerOrDisablePrescription(prescription) {
      if (prescription.idProntuario === this.idProntuario) {
        this.idPrescriptionToRemove = prescription.idPrescricaoMemed

        this.$bvModal.show(`prescription_to_remove_modal`)
      } else {
        this.showJustifyModal(prescription.id)
      }
    },
    async removePrescriptions() {
      const payload = {
        idPrescription: this.prescriptionsToRemove[0].idPrescricaoMemed,
        idProntuario: this.idProntuario,
        token: this.token
      }

      await this.removePrescriptionAction({ payload })
      this.$bvModal.hide(`prescription_to_remove_modal`)
    },
    disablePrescription(prescription) {
      const object = {
        ...prescription,
        justificativaInativacao: this.justify
      }
      this.disablePrescriptionAction(object)
      this.hideJustifyModal(prescription.id)
    },

    enablePrescription(prescription) {
      this.enablePrescriptionAction(prescription)
    },
    showJustifyModal(prescriptionId) {
      this.$bvModal.show(`prescription_justification_${prescriptionId}`)
    },
    hideJustifyModal(prescriptionId) {
      this.justify = ''
      this.$bvModal.hide(`prescription_justification_${prescriptionId}`)
    },
    showObservationModal(prescription) {
      this.observation = prescription.observacao
      this.$bvModal.show(`prescription_observation_${prescription.id}`)
    },
    hideObservationModal(prescriptionId) {
      this.observation = ''
      this.$bvModal.hide(`prescription_observation_${prescriptionId}`)
    },

    editPrescriptionObservation(prescription) {
      const object = {
        ...prescription,
        observacao: this.observation
      }

      this.editPrescription(object)

      this.hideObservationModal(prescription.id)
    },
    editPrescriptionAdherence(adherenceId, prescription) {
      const object = {
        ...prescription,
        idTipoAderencia: adherenceId
      }

      this.editPrescription(object)
    },
    editPrescriptionMedicineUse(event, prescription) {
      const object = {
        ...prescription,
        usoContinuo: event.target.checked === false ? 0 : 1
      }
      this.editPrescription(object)
    },
    editPrescription(prescription) {
      this.editPrescriptionAction(prescription)
    },
    removeTagsFromStrings(string) {
      const text = removeTagsFromString(string)

      return text
    },
    stripHtmlTags(input) {
      if (!input) return '' // Garante que valores nulos ou indefinidos retornem uma string vazia
      return input.replace(/<[^>]*>/g, '') // Remove todas as tags HTML
    },
    definePrescriptionAction(prescription) {
      if (prescription.idProntuario === this.idProntuario) {
        return 'Remover'
      } else {
        return 'Inativar'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table-container {
  height: 35vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

td {
  font-size: 10px;
}

.table-header:not(:first-child),
td:not(:first-child) {
  text-align: center;
}

.line-throughed {
  text-decoration: line-through;
}

th {
  font-weight: 400 !important;
  background-color: #089bab;
  position: sticky;
  top: 0px;
  z-index: 1;
  max-height: 35px !important;
  height: 19px !important;
  padding: 8px !important;
  border: none !important;
}

th:nth-child(1) {
  width: 150px;
  white-space: nowrap;
}

th:nth-child(2) {
  width: auto;
}

th:nth-child(3) {
  width: auto;
}

th:nth-child(4) {
  width: 30px;
}

th:nth-child(5) {
  width: auto;
}

th:nth-child(6) {
  width: 150px;
}

th:nth-child(7) {
  width: 150px;
}

th:nth-child(8) {
  width: 100px;
}

@media (min-height: 600px) {
  .table-container {
    height: 40vh;
  }
}

@media (min-height: 700px) {
  .table-container {
    height: 45vh;
  }
}

@media (min-height: 800px) {
  .table-container {
    height: 50vh;
  }
}

@media (min-height: 900px) {
  .table-container {
    height: 53vh;
  }
}

@media (max-width: 727px) {
  td {
    font-size: 0.5rem;
  }
  .table-header:not(:first-child),
  td:not(:first-child) {
    text-align: center;
    font-size: 0.5rem;
  }
  .aderencia {
    width: none;
  }
  .button-pdf {
    padding: 6px !important;
    padding-left: 10px !important;
  }
  .removeAndAtivar {
    padding: 6px !important;
    font-size: 0.6rem !important;
  }
  .data {
    font-size: 0.5rem !important;
  }
}

@media (max-width: 525px) {
  .observ {
    display: none;
  }
}

@media (max-width: 458px) {
  td {
    font-size: 0.45rem;
  }
  .table-header:not(:first-child),
  td:not(:first-child) {
    text-align: center;
    font-size: 0.45rem;
    padding: 4px !important;
  }
  .aderencia {
    display: none;
  }
  .button-pdf {
    padding: 6px !important;
    padding-left: 10px !important;
  }
  .removeAndAtivar {
    padding: 6px !important;
    font-size: 0.45rem !important;
  }
  .data {
    font-size: 0.45rem !important;
  }
}
</style>
