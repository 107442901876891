import { render, staticRenderFns } from "./AddNewExam.vue?vue&type=template&id=57708860&scoped=true"
import script from "./AddNewExam.vue?vue&type=script&lang=js"
export * from "./AddNewExam.vue?vue&type=script&lang=js"
import style0 from "./AddNewExam.vue?vue&type=style&index=0&id=57708860&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57708860",
  null
  
)

export default component.exports