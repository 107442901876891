<template>
  <div>
    <div id="box-ultrassom">
      <nav class="navbar-tests">
        <div class="navbar-ultrassom-title">Data</div>
        <div class="navbar-ultrassom-box">
          <div class="navbar-ultrassom-span-ig">IG USG</div>
          <div class="navbar-ultrassom-span-dpp">DPP</div>
          <div class="navbar-ultrassom-span-peso">Peso</div>
          <div class="navbar-ultrassom-span">Placenta</div>
          <div class="navbar-ultrassom-span">Liquido</div>
          <div class="navbar-ultrassom-span">Trans.Nucal</div>
          <div class="navbar-ultrassom-span">Vesícula</div>
          <div class="navbar-ultrassom-obs">Observação</div>
          <div class="navbar-ultrassom-laudo">Laudo</div>
        </div>
      </nav>
      <div class="box-ultra">
        <div
          :class="index % 2 !== 0 ? 'ultra-card-imp' : 'ultra-card'"
          v-for="(ultrassom, index) in getListUtrassom"
          :key="index"
        >
          <input
            id="ultra-input-data"
            type="text"
            :value="formatData(ultrassom.dtInclusao)"
            @input="handleDateChange($event, index)"
          />
          <div class="box-input">
            <div class="ultra-box-ig">
              <ValidationObserver>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <input
                    :style="
                      errors[0] || ultrassom.igUsgQuantidadeSemana === 0
                        ? 'border: 1px solid red'
                        : 'ultra-input-ig-week'
                    "
                    :placeholder="ultrassom.igUsgQuantidadeSemana + ' semanas'"
                    class="ultra-input-ig-week"
                    type="number"
                    :disabled="ultrassom.id ? true : false"
                    @input="handleWeek($event, index)"
                  />
                </ValidationProvider>
              </ValidationObserver>
              <ValidationObserver>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <input
                    :style="
                      errors[0] || ultrassom.igUsgQuantidadeDia === 0
                        ? 'border: 1px solid red'
                        : 'ultra-input-ig-day'
                    "
                    :placeholder="ultrassom.igUsgQuantidadeDia + ' dias'"
                    class="ultra-input-ig-day"
                    type="number"
                    :disabled="ultrassom.id ? true : false"
                    @input="handleDay($event, index)"
                  />
                </ValidationProvider>
              </ValidationObserver>
            </div>
            <ValidationObserver>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <input
                  :style="
                    errors[0] || ultrassom.dppUsg === ''
                      ? 'border: 1px solid red'
                      : 'ultra-input-dpp'
                  "
                  id="ultra-input-dpp"
                  :value="formatDateForInput(ultrassom.dppUsg)"
                  type="date"
                  :disabled="ultrassom.id ? true : false"
                  @input="handleDppUsg($event, index)"
                />
              </ValidationProvider>
            </ValidationObserver>
            <ValidationObserver>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <input
                  :style="
                    errors[0] || ultrassom.peso === 0
                      ? 'border: 1px solid red'
                      : 'ultra-input-normal'
                  "
                  class="ultra-input-normal"
                  type="number"
                  :disabled="ultrassom.id ? true : false"
                  min="0"
                  :value="ultrassom.peso"
                  @input="handlePeso($event, index)"
                />
              </ValidationProvider>
            </ValidationObserver>
            <ValidationObserver>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <input
                  :style="
                    errors[0] || ultrassom.placenta === ''
                      ? 'border: 1px solid red'
                      : 'ultra-input-pacient'
                  "
                  class="ultra-input-pacient"
                  :value="ultrassom.placenta"
                  :disabled="ultrassom.id ? true : false"
                  type="text"
                  @input="handlePlacenta($event, index)"
                />
              </ValidationProvider>
            </ValidationObserver>
            <ValidationObserver>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <input
                  :style="
                    errors[0] || ultrassom.liquido === ''
                      ? 'border: 1px solid red'
                      : 'ultra-input-liguid'
                  "
                  class="ultra-input-liguid"
                  :value="ultrassom.liquido"
                  :disabled="ultrassom.id ? true : false"
                  type="text"
                  @input="handleLiquido($event, index)"
                />
              </ValidationProvider>
            </ValidationObserver>

            <input
              class="ultra-input-nucal"
              :value="ultrassom.transucaoNucal"
              type="text"
              :disabled="ultrassom.id ? true : false"
              @input="handleTransucaoNucal($event, index)"
            />

            <input
              class="ultra-input-veci"
              :value="ultrassom.vesicula"
              type="text"
              :disabled="ultrassom.id ? true : false"
              @input="handleVesicula($event, index)"
            />

            <input
              class="ultra-input-obs"
              :value="ultrassom.observacao"
              type="text"
              :disabled="ultrassom.id ? true : false"
              @input="handleObsevor($event, index)"
            />
            <div class="ultra-box-buttons">
              <button
                v-if="
                  ultrassom.descArquivo !== '' &&
                  ultrassom.descArquivo.urlArquivo !== null
                "
                class="ultra-button"
                @click="openPDF(ultrassom.descArquivo)"
              >
                <img
                  class="img-pdf"
                  src="./assets/pdf.png"
                  alt="Icone de arquivo PDF"
                />
              </button>
              <button v-else class="ultra-button" @click="openModal(index)">
                <i class="fas fa-upload fa-2x" style="color: #089bab"></i>
              </button>
              <button
                class="ultra-button-delete"
                @click="deleteUltrassom(index, ultrassom.id)"
              >
                {{ ultrassom.id ? 'inativar' : 'remover' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-button
      style="
        margin-top: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      "
      @click="addNewUltrassom()"
      variant="primary"
      >Novo ultrassom</b-button
    >
    <pdf
      v-if="showModal"
      @closeModal="closeModal()"
      @add-file="setPDF"
      :valueIndex="index"
    />
    <showFromPdf v-if="showPDF" :pdf="dataPdf" @closeModal="closeShowPDF" />
  </div>
</template>
<script>
import moment from 'moment'
import pdf from './AddFileModal.vue'
import showFromPdf from './showPdf.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['idPreNatal', 'idProntuario'],
  components: { pdf, showFromPdf },
  data() {
    return {
      showPDF: false,
      showModal: false,
      index: null,
      dataPdf: null
    }
  },
  async mounted() {
    await this.getListaUltrassomAction({
      idProntuario: this.idProntuario,
      idPreNatal: this.idPreNatal
    })
  },
  methods: {
    ...mapGetters({
      getDataUltrassom: 'MedicalReports/getDataUltrassom'
    }),
    ...mapActions({
      getListaUltrassomAction: 'MedicalReports/getListaUltrassomAction',
      addFileUtrassomAction: 'MedicalReports/addFileUtrassomAction',
      addNewUltrassomAction: 'MedicalReports/addNewUltrassomAction',
      deleteUltrassomAction: 'MedicalReports/deleteUltrassomAction',
      setObeserverUltrassomAction: 'MedicalReports/setObeserverUltrassomAction',
      setVesiculaUltrassomAction: 'MedicalReports/setVesiculaUltrassomAction',
      setTransucaoNucalUltrassomAction:
        'MedicalReports/setTransucaoNucalUltrassomAction',
      setLiquidoUltrassomAction: 'MedicalReports/setLiquidoUltrassomAction',
      setPlacentaUltrassomAction: 'MedicalReports/setPlacentaUltrassomAction',
      setPesoUltrassomAction: 'MedicalReports/setPesoUltrassomAction',
      setDppUsgUltrassomAction: 'MedicalReports/setDppUsgUltrassomAction',
      setIgDayUltrassomAction: 'MedicalReports/setIgDayUltrassomAction',
      setIgWeekUltrassomAction: 'MedicalReports/setIgWeekUltrassomAction'
    }),
    handleDateChange(event, index) {
      const newDate = event.target.value
      this.setDateUltrassomAction({
        index: index,
        dtInclusao: newDate
      })
    },
    closeModal() {
      this.showModal = false
    },
    openModal(value) {
      this.index = value
      this.showModal = true
    },
    openPDF(pdf) {
      this.dataPdf = pdf
      this.showPDF = true
    },
    closeShowPDF() {
      this.showPDF = false
    },
    setPDF({ pdfDataUrl, index, selectedFile, namedFile }) {
      this.addFileUtrassomAction({
        namedFile: namedFile,
        file: selectedFile,
        urlPdf: pdfDataUrl,
        index: index
      })
    },
    addNewUltrassom() {
      this.addNewUltrassomAction({
        idPreNatal: this.idPreNatal,
        idProntuario: this.idProntuario
      })
    },
    deleteUltrassom(index, id) {
      this.deleteUltrassomAction({ index: index, id: id })
    },
    // Setando valores em seus ultrassons
    handleObsevor(event, index) {
      const text = event.target.value
      this.setObeserverUltrassomAction({
        index: index,
        observacao: text
      })
    },
    handleVesicula(event, index) {
      const text = event.target.value
      this.setVesiculaUltrassomAction({
        index: index,
        vesicula: text
      })
    },
    handleTransucaoNucal(event, index) {
      const text = event.target.value
      this.setTransucaoNucalUltrassomAction({
        index: index,
        transucaoNucal: text
      })
    },
    handleLiquido(event, index) {
      const text = event.target.value
      this.setLiquidoUltrassomAction({
        index: index,
        liquido: text
      })
    },
    handlePlacenta(event, index) {
      const text = event.target.value
      this.setPlacentaUltrassomAction({
        index: index,
        placenta: text
      })
    },
    handlePeso(event, index) {
      const text = parseFloat(event.target.value)
      this.setPesoUltrassomAction({
        index: index,
        peso: text
      })
    },
    handleDppUsg(event, index) {
      const text = event.target.value
      this.setDppUsgUltrassomAction({
        index: index,
        dppUsg: text
      })
    },
    handleDay(event, index) {
      const text = parseFloat(event.target.value)
      this.setIgDayUltrassomAction({
        index: index,
        IGusgQuantidadeDay: text
      })
    },
    handleWeek(event, index) {
      const text = parseFloat(event.target.value)
      this.setIgWeekUltrassomAction({
        index: index,
        IGusgQuantidadeWeek: text
      })
    },
    // Formatação de data
    formatData(data) {
      if (data === '') {
        return 'Sem informação.'
      } else {
        const dataFormatada = moment(data).format('DD/MM/YYYY')
        return dataFormatada
      }
    },
    // Data dos inputs
    formatDateForInput(dateString) {
      if (dateString === '') {
        return ''
      } else {
        // Converte a string de data para um objeto Date
        const dateObject = new Date(dateString)
        // Formata a data no formato 'YYYY-MM-DD'
        const formattedDate = dateObject.toISOString().split('T')[0]
        return formattedDate
      }
    }
  },

  computed: {
    // Carregando lista de ultrassons
    getListUtrassom() {
      return this.getDataUltrassom()
    }
  }
}
</script>
<style scoped>
#box-ultrassom {
  display: flex;
  height: 220px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.navbar-tests {
  width: 100%;
  display: flex;
  padding: 2px 20px 2px 15px;
  align-items: center;
  justify-content: space-between;
  background-color: #089bab;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  gap: 10px;
}

/* Linha superior da tabela */
.navbar-ultrassom-title {
  width: 140px;
  font-weight: 600;
  color: white;
}
.navbar-ultrassom-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.navbar-ultrassom-span {
  font-weight: 600;
  color: white;
  padding: 8px;
}

.navbar-ultrassom-laudo {
  width: 120px;
  font-weight: 600;
  color: white;
  padding: 8px;
}

.navbar-ultrassom-span-peso {
  width: 60px;
  font-weight: 600;
  color: white;
  padding: 8px;
}
.navbar-ultrassom-span-dpp {
  width: 140px;
  font-weight: 600;
  color: white;
  padding: 8px;
}
.navbar-ultrassom-span-ig {
  width: 142px;
  font-weight: 600;
  color: white;
  padding: 8px;
}

.navbar-ultrassom-obs {
  width: 200px;
  font-weight: 600;
  color: white;
  padding: 8px;
}

.box-ultra {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
}

.ultra-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px 10px 10px;
  justify-content: space-between;
  gap: 10px;
}

.ultra-card-imp {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px 10px 10px;
  justify-content: space-between;
  background-color: #daf0f3;
  gap: 10px;
}

/* Linhas da tabela e seus inputs */
.box-input {
  display: flex;
  gap: 30px;
  align-items: center;
}

#ultra-input-data {
  width: 140px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}
.ultra-box-ig {
  width: 142px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.ultra-input-ig-day {
  width: 54px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.ultra-input-ig-week {
  width: 86px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}
.ultra-input-normal {
  width: 60px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}

#ultra-input-dpp {
  width: 140px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}

.ultra-input-pacient {
  width: 70px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}

.ultra-input-liguid {
  width: 60px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}

.ultra-input-nucal {
  width: 88px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}

.ultra-input-veci {
  width: 68.6px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}

.ultra-input-obs {
  width: 200px;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 8px;
}
.ultra-button {
  border: none;
  background: transparent;
  border-radius: 6px;
}
.ultra-button-delete {
  color: white;
  padding: 7px;
  border: none;
  background: rgb(209, 47, 47);
  border-radius: 6px;
}

.ultra-box-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.img-upload {
  height: 24px;
  width: 24px;
}

.img-pdf {
  height: 24px;
  width: 24px;
}
/* Responsive */
@media (max-width: 1399px) {
  .box-input {
    gap: 10px;
  }
  .navbar-ultrassom-box {
    gap: 10px;
  }
  .ultra-box-ig {
    width: 110px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .navbar-ultrassom-title {
    width: 110px;
    font-size: 0.6rem;
  }

  .navbar-ultrassom-span {
    font-size: 0.6rem;
  }

  .navbar-ultrassom-span-peso {
    width: 40px;
    font-size: 0.6rem;
  }
  .navbar-ultrassom-span-dpp {
    width: 110px;
    font-size: 0.6rem;
  }
  .navbar-ultrassom-span-ig {
    width: 130px;
    font-size: 0.6rem;
  }

  .navbar-ultrassom-laudo {
    width: 120px;
    font-size: 0.6rem;
  }

  .navbar-ultrassom-obs {
    width: 150px;
    font-size: 0.6rem;
  }

  /* Responsive inputs */
  #ultra-input-data {
    width: 110px;
    font-size: 0.6rem;
    padding: 5px;
  }
  .ultra-box-ig {
    width: 130px;
    font-size: 0.6rem;
    padding: 6px;
  }

  .ultra-input-ig-day {
    width: 40px;
    font-size: 0.6rem;
    padding: 6px;
  }

  .ultra-input-ig-week {
    width: 70px;
    font-size: 0.6rem;
    padding: 6px;
  }
  .ultra-input-normal {
    width: 40px;
    font-size: 0.6rem;
    padding: 6px;
  }

  #ultra-input-dpp {
    width: 110px;
    font-size: 0.6rem;
    padding: 5px;
  }

  .ultra-input-pacient {
    width: 60px;
    font-size: 0.6rem;
    padding: 6px;
  }

  .ultra-input-liguid {
    width: 50px;
    font-size: 0.6rem;
    padding: 6px;
  }

  .ultra-input-nucal {
    width: 74px;
    font-size: 0.6rem;
    padding: 6px;
  }

  .ultra-input-veci {
    width: 58px;
    font-size: 0.6rem;
    padding: 6px;
  }

  .ultra-input-obs {
    width: 150px;
    font-size: 0.6rem;
    padding: 6px;
  }
}
@media (max-width: 1100px) {
  .ultra-box-ig {
    width: 120px;
    padding: none;
    gap: 1px;
  }
  .box-input {
    gap: 2px;
  }
  .navbar-ultrassom-box {
    gap: 2px;
  }
  .navbar-ultrassom-title {
    width: 110px;
    font-size: 0.5rem;
  }

  .navbar-ultrassom-span {
    font-size: 0.5rem;
  }

  .navbar-ultrassom-span-peso {
    width: 60px;
    font-size: 0.5rem;
  }
  .navbar-ultrassom-span-dpp {
    width: 90px;
    font-size: 0.5rem;
  }
  .navbar-ultrassom-span-ig {
    width: 120px;
    font-size: 0.5rem;
  }

  .navbar-ultrassom-obs {
    width: 100px;
    font-size: 0.5rem;
  }
  .navbar-ultrassom-laudo {
    width: 100px;
    font-size: 0.5rem;
  }

  /* Responsive inputs */
  #ultra-input-data {
    width: 110px;
    font-size: 0.5rem;
    padding: 4px;
  }

  .ultra-input-ig-day {
    width: 40px;
    font-size: 0.5rem;
    padding: 5px;
  }

  .ultra-input-ig-week {
    width: 70px;
    font-size: 0.5rem;
    padding: 5px;
  }
  .ultra-input-normal {
    width: 60px;
    font-size: 0.5rem;
    padding: 5px;
  }

  #ultra-input-dpp {
    width: 90px;
    font-size: 0.5rem;
    padding: 4px;
  }

  .ultra-input-pacient {
    width: 52px;
    font-size: 0.5rem;
    padding: 5px;
  }

  .ultra-input-liguid {
    width: 46px;
    font-size: 0.5rem;
    padding: 5px;
  }

  .ultra-input-nucal {
    width: 64px;
    font-size: 0.5rem;
    padding: 5px;
  }

  .ultra-input-veci {
    width: 51px;
    font-size: 0.5rem;
    padding: 6px;
  }

  .ultra-input-obs {
    width: 100px;
    font-size: 0.5rem;
    padding: 6px;
  }

  .ultra-button-delete {
    padding: 6px;
    font-size: 0.6rem;
  }

  .ultra-box-buttons {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .img-upload {
    height: 22px;
    width: 22px;
  }

  .img-pdf {
    height: 22px;
    width: 22px;
  }
}

@media (max-width: 890px) {
  #ultra-input-data {
    width: 60px;
    font-size: 0.5rem;
    padding: 4px;
  }
  .ultra-input-ig-week {
    width: 58px;
  }
  .navbar-ultrassom-span-peso {
    width: 40px;
  }
  .ultra-input-normal {
    width: 40px;
  }
}
</style>
