<template>
  <b-modal
    :id="`asoForm`"
    header-bg-variant="primary"
    :title="
      activeOption === 'ASO' ? 'Emissão de ASO' : 'Emissão de Acuidade Visual'
    "
    size="xl"
    title-class="text-light"
    ok-title="Salvar"
    hide-footer
    @close="close"
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="aso-template">
      <!-- Botões para alternar as opções -->
      <div class="aso-options">
        <button
          @click="switchOption('ASO')"
          :class="[
            'aso-button',
            { active: activeOption === 'ASO', inactive: activeOption !== 'ASO' }
          ]"
        >
          Form ASO
        </button>
        <div class="divider"></div>
        <button
          @click="switchOption('Acuidade Visual')"
          :class="[
            'aso-button',
            {
              active: activeOption === 'Acuidade Visual',
              inactive: activeOption !== 'Acuidade Visual'
            }
          ]"
        >
          Acuidade Visual
        </button>
      </div>
      <ValidationObserver
        v-show="activeOption === 'ASO'"
        ref="form"
        v-slot="{ handleSubmit }"
      >
        <form id="form" @submit.prevent="handleSubmit(saveASO)">
          <b-row class="section-title">
            <b-col md="12">
              <span>Empresa</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="10">
              <SearchEnterprise
                @selectedEnterprise="
                  (value) => {
                    enterprise = value
                  }
                "
              />
            </b-col>

            <b-col md="2">
              <b-form-checkbox v-model="isNewEnterprise" v-if="asoCanBeEdited">
                Nova empresa
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label>Profissional:</label>
                <b-form-input
                  :disabled="true"
                  :value="user.nome"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                />
                <div class="invalid-feedback">
                  <span>Selecione um profissional</span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="4">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label>Razão Social:</label>
                <b-form-input
                  ref="corporateName"
                  :disabled="!isNewEnterprise"
                  v-model="enterprise.corporateName"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                />
                <div class="invalid-feedback">
                  <span>Informe sua empresa</span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="4">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label>CNPJ:</label>
                <b-form-input
                  :disabled="!isNewEnterprise"
                  v-model="enterprise.cnpj"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                  v-mask="'##.###.###/####.##'"
                />
                <div class="invalid-feedback">
                  <span>Informe o CNPJ</span>
                </div>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="5">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label>Endereço:</label>
                <b-form-input
                  :disabled="!isNewEnterprise"
                  v-model="enterprise.address"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                />
                <div class="invalid-feedback">
                  <span>Informe um endereço</span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="2">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label>Nº:</label>
                <b-form-input
                  v-mask="'##############'"
                  :disabled="!isNewEnterprise"
                  v-model="enterprise.number"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                />
                <div class="invalid-feedback">
                  <span>Informe um número</span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="5">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label>Bairro:</label>
                <b-form-input
                  :disabled="!isNewEnterprise"
                  v-model="enterprise.neighborhood"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                />
                <div class="invalid-feedback">
                  <span>Informe um bairro</span>
                </div>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <ValidationProvider rules="required">
                <label>Estado:</label>
                <v-select
                  :disabled="!isNewEnterprise"
                  :clearable="false"
                  v-model="enterprise.state"
                  :options="states"
                  :reduce="(descEstado) => descEstado.id"
                  label="descEstado"
                ></v-select>
                <div class="invalid-feedback">
                  <span>Informe um estado</span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="required">
                <label>Cidade:</label>
                <v-select
                  :disabled="!isNewEnterprise"
                  :clearable="false"
                  v-model="enterprise.city"
                  :options="cities"
                  :reduce="(cidade) => cidade.id"
                  label="descCidade"
                ></v-select>
                <div class="invalid-feedback">
                  <span>Informe um estado</span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label>CEP:</label>
                <b-form-input
                  :disabled="!isNewEnterprise"
                  v-model="enterprise.cep"
                  v-mask="'#####-###'"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                />
                <div class="invalid-feedback">
                  <span>Informe um CEP</span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <label>Complemento:</label>
              <b-form-input
                v-model="enterprise.complement"
                :disabled="!isNewEnterprise"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="10">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label>Cargo:</label>
                <b-form-input
                  ref="newOffice"
                  v-if="isNewOffice"
                  v-model="office"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                />

                <v-select
                  v-else
                  :disabled="!asoCanBeEdited"
                  :clearable="false"
                  :options="offices"
                  label="descCargo"
                  :reduce="(item) => item.id"
                  v-model="office"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                />
                <div class="invalid-feedback">
                  <span>Informe um cargo</span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="2" style="display: flex; align-items: center">
              <b-form-checkbox v-model="isNewOffice" v-if="asoCanBeEdited">
                Novo cargo
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="10">
              <ValidationProvider
                :rules="isNewPMCSO ? '' : 'required'"
                v-slot="{ errors }"
              >
                <label>Responsavel PMCSO:</label>
                <b-form-input
                  :disabled="isNewPMCSO"
                  v-model="responsavelPmcso"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                />
                <div class="invalid-feedback">
                  <span>Informe o responsavel</span>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="2" style="display: flex; align-items: center">
              <b-form-checkbox @input="handleIsNewPMCSO()">
                Adicionar Responsavel
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row class="section-title">
            <b-col md="12">
              <span>Fatores de risco</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col :md="isNewRiskFactor ? '9' : '10'">
              <b-form-input
                ref="newRiskFactor"
                v-if="isNewRiskFactor"
                v-model="newRiskFactor"
              />

              <SearchDanger
                v-if="asoCanBeEdited ? (isNewRiskFactor ? false : true) : false"
                :idClinic="idClinic"
                @selectedRiskFactor="
                  (value) => {
                    riskFactors.push(value)
                  }
                "
              />
            </b-col>

            <b-col md="1" v-if="isNewRiskFactor">
              <b-button
                size="large"
                variant="primary"
                @click="includeNewFactorRisk"
                :disabled="loading"
              >
                Incluir
              </b-button>
            </b-col>

            <b-col md="2" style="display: flex; align-items: center">
              <b-form-checkbox v-model="isNewRiskFactor" v-if="asoCanBeEdited">
                Novo fator de risco
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <button
                type="button"
                v-for="(item, index) in newRiskFactors"
                :key="item.descFatorRisco"
                class="tagItem"
                @click="newRiskFactors.splice(index, 1)"
                :disabled="!asoCanBeEdited"
              >
                {{ item.descFatorRisco }}
                <b-icon icon="x" />
              </button>

              <button
                type="button"
                v-for="(item, index) in riskFactors"
                :key="item.id"
                class="tagItem"
                @click="riskFactors.splice(index, 1)"
                :disabled="!asoCanBeEdited"
              >
                {{ item.descFatorRisco }}
                <b-icon icon="x" />
              </button>
            </b-col>
          </b-row>

          <b-row class="section-title">
            <b-col md="12">
              <span>Tipo de atestado:</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-select
                  :clearable="false"
                  :options="licenceTypes"
                  v-model="licenceType"
                  :reduce="(item) => item.id"
                  label="descTipoAtestado"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                  :disabled="!asoCanBeEdited"
                />
                <div class="invalid-feedback">
                  <span>Informe um tipo</span>
                </div>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row class="section-title">
            <b-col md="12">
              <span>Avaliação clínica e exames realizados:</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <v-select
                :clearable="false"
                :options="examPeriods"
                v-model="examPeriod"
                :reduce="(item) => item.value"
                label="description"
                :disabled="!asoCanBeEdited || licenceType === 3"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="10">
              <SearchService
                v-if="asoCanBeEdited"
                @selectedService="
                  (value) => {
                    services.push(value)
                  }
                "
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <div class="scrollable-container">
                <div
                  v-for="(item, index) in services"
                  :key="item.id"
                  class="item-list-services"
                  :disabled="!asoCanBeEdited"
                >
                  <p
                    class="text-service"
                    :title="`${item.codigoServico} - ${item.descServico}`"
                  >
                    {{
                      item.descServico.length > 20
                        ? item.descServico.substring(0, 80) + '...'
                        : item.descServico
                    }}
                  </p>

                  <div class="date-and-delete">
                    <b-form-input
                      type="date"
                      :max="dataAtual"
                      :value="formatDateForInput(item.dataResultado)"
                      @input="setDataResultado($event, index)"
                      style="width: 140px"
                      :disabled="!asoCanBeEdited"
                    />

                    <div
                      :class="
                        !asoCanBeEdited
                          ? 'button-delete-service-disable'
                          : 'button-delete-service'
                      "
                      @click="
                        !asoCanBeEdited ? () => {} : services.splice(index, 1)
                      "
                    >
                      x
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <label>Exame Físico:</label>
              <b-form-textarea
                style="height: 60px"
                v-model="observation"
                :disabled="!asoCanBeEdited"
                rows="1"
                no-resize
              />
            </b-col>
          </b-row>

          <b-row class="section-title">
            <b-col md="12">
              <span>Parecer:</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-select
                  :clearable="false"
                  :options="medicOpinions"
                  v-model="medicOpinion"
                  :reduce="(item) => item.id"
                  label="descParecer"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                  :disabled="!asoCanBeEdited"
                />
                <div class="invalid-feedback">
                  <span>Informe um cargo</span>
                </div>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="11"> </b-col>

            <b-col md="1">
              <b-button
                v-if="asoCanBeEdited"
                size="large"
                variant="primary"
                type="submit"
                :disabled="loading"
              >
                Salvar
              </b-button>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
      <VisualAcuity
        :idHandbook="idHandbook"
        :idClinic="idClinic"
        :idPatient="idPatient"
        :acuityToEdit="acuityToEdit"
        v-show="activeOption === 'Acuidade Visual'"
        @close="close"
        @saved="savedAcuity"
      />
    </div>
  </b-modal>
</template>

<script>
import SearchEnterprise from '../../../../ASO/SearchEnterprise'
import SearchDanger from '../../../../ASO/SearchDanger'
import SearchService from '../../../../ASO/SearchService'
import VisualAcuity from './visualAcuity.vue'
import { mapActions, mapGetters } from 'vuex'
import { nextTick } from 'vue'

export default {
  components: {
    SearchEnterprise,
    SearchDanger,
    SearchService,
    VisualAcuity
  },
  props: [
    'idClinic',
    'idHandbook',
    'idPatient',
    'medicOpinions',
    'licenceTypes',
    'asoToEdit',
    'acuityToEdit',
    'options'
  ],
  data() {
    return {
      dataAtual: this.formatDateForInput(new Date()),
      loading: false,
      states: [],
      cities: [],
      offices: [],
      medicOpinion: '',
      activeOption: this.options || 'ASO',
      licenceType: '',
      examPeriods: [
        { id: 1, description: '6 meses', value: 6 },
        { id: 2, description: '12 meses', value: 12 }
      ],
      examPeriod: '',
      isNewEnterprise: false,
      isNewOffice: false,
      isNewPMCSO: true,
      isNewRiskFactor: false,
      professional: '',
      enterprise: {
        id: '',
        corporateName: '',
        cnpj: '',
        address: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: '',
        complement: ''
      },
      office: '',
      responsavelPmcso: '',
      riskFactors: [],
      newRiskFactors: [],
      newRiskFactor: '',
      services: [],
      observation: '',
      asoToEditHandbookId: ''
    }
  },
  watch: {
    'enterprise.state': function () {
      this.handleGetCities()
    },
    isNewEnterprise(newValue) {
      this.enterprise = {
        id: '',
        corporateName: '',
        cnpj: '',
        address: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: '',
        complement: ''
      }

      if (newValue) {
        nextTick(() => this.$refs.corporateName.focus())
      }
    },
    isNewOffice(newValue) {
      this.office = ''
      if (newValue) {
        nextTick(() => this.$refs.newOffice.focus())
      }
    }
  },
  computed: {
    ...mapGetters({ user: 'Setting/user' }),
    asoCanBeEdited() {
      if (this.asoToEditHandbookId) {
        return this.idHandbook === this.asoToEditHandbookId
      }
      return true
    }
  },
  methods: {
    ...mapActions({
      getStates: 'Setting/getStatesAction',
      getCitiesByStateId: 'Setting/getCitiesByStateIdAction',
      getOfficesByClinic: 'ASO/getOfficesByClinic',
      createASOEnterprise: 'ASO/createASOEnterprise',
      createASOOffice: 'ASO/createASOOffice',
      createRiksFactor: 'ASO/createRiksFactor',
      getMedicEnterpriseByMedic: 'ASO/getMedicEnterpriseByMedic',
      createMedicEnterpriseRelation: 'ASO/createMedicEnterpriseRelation',
      addASOToHandBook: 'MedicalReports/addASOToHandBook',
      editASOFromHandBook: 'MedicalReports/editASOFromHandBook',
      getASO: 'ASO/getCompleteASOByIdAndClinic'
    }),
    savedAcuity() {
      this.$emit('saved')
    },
    close() {
      this.$emit('close')
    },
    handleIsNewPMCSO() {
      this.isNewPMCSO = !this.isNewPMCSO
    },
    switchOption(option) {
      this.activeOption = option // Atualiza a opção ativa
      this.$emit('update:options', option) // Comunica a mudança para o componente pai (se necessário)
    },
    includeNewFactorRisk() {
      this.newRiskFactors.push({
        descFatorRisco: this.newRiskFactor,
        ativo: 1,
        idClinica: this.idClinic
      })

      this.newRiskFactor = ''
    },
    async saveASO() {
      try {
        this.loading = true
        if (this.newRiskFactors.length > 0) {
          await this.handleCreateRiskFactors()
        }
        if (this.isNewEnterprise) {
          const id = await this.handleCreateNewEnterprise()
          this.enterprise.id = id
        } else {
          const verif = await this.verifyMedicEnterpriseRelation()

          if (!verif) {
            await this.handleCreateMedicEnterpriseRelation()
          }
        }

        if (this.isNewOffice) {
          const id = await this.handleCreateNewOffice()
          this.office = id
        }

        const object = {
          idProntuario: this.idHandbook,
          dtInclusao: new Date().toISOString(),
          dtOcorrencia: new Date().toISOString(),
          idMedEmpresaTrabalho: this.enterprise.id,
          razaoSocialEmpresa: this.enterprise.corporateName,
          idMedicoTrabalho: this.user.id,
          idCargoTrabalho: this.office,
          idMedTrabalhoTipoAtestado: this.licenceType,
          idMedTrabalhoParecer: this.medicOpinion,
          tempoValidadeAvaliacaoMeses: this.examPeriod,
          observacaoAso: this.observation,
          idPaciente: this.idPatient,
          responsavelPcmso: this.responsavelPmcso,
          ativo: 0,
          listaProntuarioAsoFatorRisco: this.riskFactors,
          listaProntuarioAsoExame: this.services
        }
        if (this.asoToEdit) {
          object.id = this.asoToEdit
          await this.editASOFromHandBook({ payload: object })
        } else {
          await this.addASOToHandBook({ payload: object })
        }

        this.$emit('saved')
        this.close()
      } catch (e) {
        this.$toast.error(e.response.message)
      } finally {
        this.loading = false
      }
    },
    async handleGetStates() {
      const states = await this.getStates()

      this.states = states
    },
    async handleGetCities() {
      const cities = await this.getCitiesByStateId(this.enterprise.state)

      this.cities = cities
    },
    async handleGetOffices() {
      const response = await this.getOfficesByClinic(this.idClinic)

      this.offices = response
    },
    async handleCreateNewEnterprise() {
      const object = {
        razaoSocial: this.enterprise.corporateName,
        cnpj: this.enterprise.cnpj,
        logradouro: this.enterprise.address,
        numero: parseInt(this.enterprise.number),
        bairro: this.enterprise.neighborhood,
        complemento: this.enterprise.complement,
        cep: this.enterprise.cep,
        idCidade: this.enterprise.city,
        idEstado: this.enterprise.state,
        idClinica: this.idClinic,
        idUsuarioInclusao: this.user.id,
        ativo: 0,
        dtInclusao: new Date().toISOString()
      }

      const response = await this.createASOEnterprise(object)

      return response.data.object.id
    },

    async handleCreateRiskFactors() {
      const promises = this.newRiskFactors.map((item) => {
        return this.createRiksFactor(item)
      })

      const resolves = await Promise.all(promises)

      resolves.forEach((element) => {
        const { id, descFatorRisco } = element
        this.riskFactors.push({
          idFatorRisco: id,
          descFatorRisco
        })
      })
    },

    async handleCreateNewOffice() {
      const object = {
        descCargo: this.office,
        idClinica: this.idClinic,
        ativo: 0
      }
      const response = await this.createASOOffice(object)

      return response.data.object.id
    },

    async verifyMedicEnterpriseRelation() {
      const relations = await this.getMedicEnterpriseByMedic(this.user.id)

      const item = relations.find((element) => {
        return element.idMedTrabalhoEmpresa === this.enterprise.id
      })

      if (item) {
        return item.id
      } else {
        return false
      }
    },

    async handleCreateMedicEnterpriseRelation() {
      const id = await this.createMedicEnterpriseRelation({
        idMedico: this.user.id,
        idMedTrabalhoEmpresa: this.enterprise.id
      })

      return id
    },

    async handleCreatePatientEnterpriseRelation() {
      const id = await this.createMedicEnterpriseRelation({
        idPaciente: this.idPatient,
        idMedTrabalhoEmpresa: this.enterprise.id
      })

      return id
    },

    formatDateForInput(dateString) {
      if (dateString === '' || dateString === null) {
        return ''
      } else {
        // Converte a string de data para um objeto Date
        const dateObject = new Date(dateString)
        // Formata a data no formato 'YYYY-MM-DD'
        const formattedDate = dateObject.toISOString().split('T')[0]
        return formattedDate
      }
    },

    setDataResultado(value, index) {
      this.services[index].dataResultado = value
    },

    async setASOToEdit() {
      const aso = await this.getASO({
        idClinic: this.idClinic,
        idASO: this.asoToEdit
      })

      const { dadosEmpresa } = aso

      this.asoToEditHandbookId = aso.idProntuario
      this.medicOpinion = aso.idMedTrabalhoParecer
      this.licenceType = aso.idMedTrabalhoTipoAtestado
      this.office = aso.idCargoTrabalho
      this.riskFactors = aso.listaProntuarioAsoFatorRisco ?? []
      this.services = aso.listaProntuarioAsoExame ?? []
      this.observation = aso.observacaoAso
      this.examPeriod = aso.tempoValidadeAvaliaçãoMeses
      this.responsavelPmcso = aso.responsavelPcmso

      this.enterprise = {
        id: dadosEmpresa.id,
        corporateName: dadosEmpresa.razaoSocial,
        cnpj: dadosEmpresa.cnpj,
        address: dadosEmpresa.logradouro,
        number: dadosEmpresa.numero,
        neighborhood: dadosEmpresa.bairro,
        city: dadosEmpresa.idCidade,
        state: dadosEmpresa.idEstado,
        cep: dadosEmpresa.cep,
        complement: dadosEmpresa.complemento
      }
    }
  },
  async mounted() {
    this.$bvModal.show('asoForm')
    this.handleGetStates()
    await this.handleGetOffices()
    if (this.asoToEdit) {
      this.setASOToEdit()
    }
  }
}
</script>

<style scoped>
.aso-template {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.aso-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aso-button {
  padding: 16px;
  flex: 1;
  font-size: 0.9rem;
  background-color: #089bab;
  color: white;
  border: none;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom: 3px solid #3a878f;
}
.aso-button.active {
  background-color: #089bab;
  color: white;
  font-weight: bold;
}

.aso-button.inactive {
  background-color: white;
  color: #089bab;
  font-weight: normal;
}

.divider {
  height: 57px;
  width: 2px;
  background-color: #b7b9b9;
}

.aso-button-disabled {
  padding: 16px;
  flex: 1;
  font-size: 0.9rem;
  background-color: transparent;
  color: #089bab;
  border: none;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom: 3px solid #3a878f;
}
.row {
  margin-bottom: 10px;
}

.section-title {
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
  margin-bottom: 10px;
}

.section-title:not(:first-child) {
  margin-top: 20px;
}

.tagItem {
  font-size: 14px;
  font-weight: 500;
  color: white;
  border: none;
  background-color: #089bab;
  height: 30px;
  padding: 0 10px 0 10px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.tagItem:not(:first-child) {
  margin-left: 10px;
}

.scrollable-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  border: 1px rgb(223, 220, 220) solid;
  border-radius: 10px;
}

.item-list-services {
  border: 1px solid #089bab;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.date-and-delete {
  display: flex;
  align-items: center;
  gap: 20px;
}

.text-service {
  align-items: center;
  margin-bottom: 0px;
  margin-top: 0px;
}

.button-delete-service {
  padding: 6px;
  height: 30px;
  width: 30px;
  border: 1px solid #089bab;
  background-color: #089bab;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-delete-service-disable {
  padding: 6px;
  height: 30px;
  width: 30px;
  border: 1px solid #b7b9b9;
  background-color: #b7b9b9;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
