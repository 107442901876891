<template>
  <ul class="table">
    <div
      v-if="data.length === 0"
      style="
        height: 400px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <span style="font-size: 1rem; font-weight: bold; color: #a09e9e"
        ><strong
          >Nenhuma receita foi encontrada, por favor revise os filtros!</strong
        ></span
      >
    </div>
    <div v-else>
      <div class="box-list-cards" v-if="isMobile()">
        <div
          title="Clique aqui para ver os detalhes e alterar!"
          v-for="(revenue, i) in listRevenue"
          :key="i"
          class="list-card"
          @click="openModalInfo(revenue)"
        >
          <div class="box-buttons-card">
            <button @click.stop="alterStatus(1, revenue)" class="statusButton">
              <b-icon icon="unlock-fill" font-scale="1"></b-icon>
              Aberto
            </button>
            <button @click.stop="alterStatus(2, revenue)" class="statusButton">
              <b-icon icon="hand-thumbs-up-fill" font-scale="1"></b-icon>
              Pago
            </button>
            <button @click.stop="alterStatus(4, revenue)" class="statusButton">
              <b-icon icon="backspace-reverse-fill" font-scale="1"></b-icon>
              Cancelado
            </button>
            <button @click.stop="alterStatus(5, revenue)" class="statusButton">
              <b-icon icon="calendar2-x-fill" font-scale="1"></b-icon>
              Em atraso
            </button>
          </div>
          <div class="box-info-card">
            <div class="content">
              <label>Recebedor:</label>
              {{ revenue.nmRecebedor }}
            </div>
            <div class="content">
              <label>Status:</label>
              {{ revenue.descStatusFinanceiro }}
            </div>
            <div class="content">
              <label>Valor:</label>
              {{ 'R$' + revenue.valor }}
            </div>
            <div class="content">
              <label>Vencimento:</label>
              {{ revenue.dtRecebimento }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <li class="headerTable">
          <input
            class="checkbox-item"
            type="checkbox"
            v-model="selectAllChecked"
            @change="handleSelectAllChange"
          />
          <span class="item recebedor">Recebedor</span>
          <span class="item type">Tipo</span>
          <span class="item titile">Titulo</span>
          <span class="item venc">Vencimento</span>
          <span class="item recebiment">Recebimento</span>
          <span class="item value">Valor</span>
          <span class="item saldo">Saldo</span>
          <span class="item discont">Desconto</span>
          <span class="item recebided">Recebido</span>
          <span class="item typePay">Tipo PG</span>
          <span class="item situetion">Situação</span>
          <span class="item status">Alterar Status</span>
        </li>
        <li
          title="Clique aqui para ver os detalhes e alterar!"
          @click="openModalInfo(revenue)"
          v-for="(revenue, i) in listRevenue"
          :key="i"
        >
          <div>
            <input
              class="checkbox-item"
              type="checkbox"
              :checked="selectAllChecked"
              @click.stop="handleCheckboxChange($event, revenue)"
            />
          </div>
          <div class="box-itens">
            <span class="item recebedor">{{ revenue.nmRecebedor }}</span>
            <span class="item type">{{ revenue.descTipoRecebimento }}</span>
            <span class="item titile">{{ revenue.numeroTitulo }}</span>
            <span class="item venc">{{
              getDateFormated(revenue.dtVencimento)
            }}</span>
            <span class="item recebiment">{{
              getDateFormated(revenue.dtRecebimento)
            }}</span>
            <span class="item value">{{
              revenue.valor === null ? 0 : 'R$' + revenue.valor
            }}</span>
            <span class="item saldo">{{
              revenue.saldo === null ? 0 : 'R$' + revenue.saldo
            }}</span>
            <span class="item discont">{{
              revenue.desconto === null ? 0 : 'R$' + revenue.desconto
            }}</span>
            <span class="item recebided">{{
              revenue.valorRecebido === null ? 0 : 'R$' + revenue.valorRecebido
            }}</span>
            <span class="item typePay">{{ revenue.tipoPagamento }}</span>
            <span class="item situetion">{{
              revenue.descStatusFinanceiro
            }}</span>
            <div>
              <b-dropdown
                id="dropdown-left"
                text="Alterar"
                variant="primary"
                class="md-2"
                @change="alterStatus()"
              >
                <b-dropdown-item
                  style="background-color: white"
                  class="statusButton mr-2"
                  @click.stop="alterStatus(1, revenue)"
                >
                  <b-icon icon="unlock-fill" font-scale="1"></b-icon>
                  Aberto
                </b-dropdown-item>

                <b-dropdown-item
                  style="background-color: white"
                  @click.stop="alterStatus(2, revenue)"
                  class="statusButton"
                >
                  <b-icon icon="hand-thumbs-up-fill" font-scale="1"></b-icon>
                  Pago
                </b-dropdown-item>
                <b-dropdown-item
                  style="background-color: white"
                  @click.stop="alterStatus(4, revenue)"
                  class="statusButton"
                >
                  <b-icon icon="backspace-reverse-fill" font-scale="1"></b-icon>
                  Cancelado
                </b-dropdown-item>
                <b-dropdown-item
                  style="background-color: white"
                  @click.stop="alterStatus(5, revenue)"
                  class="statusButton"
                >
                  <b-icon icon="calendar2-x-fill" font-scale="1"></b-icon>
                  Em atraso
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </li>
      </div>
    </div>

    <AlterGrroupRevenue
      v-if="getOpenModalAlter === true"
      @close="closeAlter()"
      :data="listRevenueSelects"
      :type="type"
      :admAndRecp="admAndRecp"
    />

    <RevenueModal
      v-if="modalInfo"
      @close="close()"
      :types="true"
      :data="dataInfo"
      :admAndRecp="admAndRecp"
      :user="user"
    />
  </ul>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import RevenueModal from '../modals/revenueModal.vue'
import AlterGrroupRevenue from '../modals/AlterGrroupRevenue.vue'
import moment from 'moment'
export default {
  props: ['admAndRecp', 'data', 'type'],
  components: { RevenueModal, AlterGrroupRevenue },
  data() {
    const listRevenue = []
    return {
      listRevenue,
      listRevenueSelects: [],
      selectAllChecked: true,
      modalInfo: false,
      modalAlter: false,
      dataInfo: null,
      typeInfo: true,
      hoveredItems: Array(listRevenue.length).fill(false),
      hovered: false,
      toggle: true,
      options: [
        { value: 1, text: 'Aberto' },
        { value: 2, text: 'Pago' },
        { value: 4, text: 'Cancelado' },
        { value: 5, text: 'Em atraso' }
      ]
    }
  },
  methods: {
    ...mapActions({
      setAlterInBlock: 'Finance/setAlterInBlock'
    }),
    handleSelectAllChange() {
      // Lógica para lidar com a mudança de estado do checkbox fora da lista
      const isChecked = this.selectAllChecked

      // Atualizar o estado de todos os checkboxes dentro da lista
      this.listRevenue.forEach((revenue) => {
        // Faça algo com cada item, por exemplo, chame handleCheckboxChange
        this.handleCheckboxChange({ target: { checked: isChecked } }, revenue)
      })
    },
    isMobile() {
      const isMobileUserAgent =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      if (isMobileUserAgent) {
        return true
      } else {
        return false
      }
    },
    async alterStatus(value, revenue) {
      if (!this.options || !Array.isArray(this.options)) {
        this.$toast.error('As opções do dropdown não estão definidas.')
        return
      }

      try {
        const selectedOption = this.options.find(
          (option) => option.value === value
        )
        if (!selectedOption) {
          this.$toast.error('Opção selecionada não encontrada.')
          return
        }

        const descStatusFinanceiro = selectedOption.text
        await this.setAlterInBlock({
          idStatusFinanceiro: value,
          idTipoRecebedor: 0,
          idReferenciaRecebedor: 0,
          list: [revenue.id]
        })
        this.listRevenue = this.listRevenue.map((expense) => {
          if (expense.id === revenue.id) {
            return {
              ...expense,
              descStatusFinanceiro // Atualize com o texto do status
            }
          }
          return expense
        })
      } catch (error) {
        this.$toast.error('Erro ao alterar status:', error)
      }
      this.$emit('update:data', this.listRevenue)
    },
    formatDate(date) {
      const dateVenci = moment(date).format('DD-MM-YYYY')
      return dateVenci
    },
    openModalInfo(object) {
      this.dataInfo = object
      this.modalInfo = true
    },
    getDateFormated(value) {
      if (value === '' || value === null || value === undefined) {
        return ''
      } else {
        const newadate = moment(value).format('DD/MM/YYYY')
        return newadate
      }
    },
    openModalAlter() {
      this.modalAlter = true
    },
    close() {
      this.modalInfo = false
    },
    closeAlter() {
      this.modalAlter = false
    },
    setHovered(index, value) {
      this.$set(this.hoveredItems, index, value)
    },
    handleCheckboxChange(value, revenue) {
      const isChecked = value.target.checked
      if (isChecked === true) {
        if (!this.listRevenueSelects.some((item) => item.id === revenue.id)) {
          this.listRevenueSelects.push(revenue)
        }
      }
      if (isChecked === false) {
        this.listRevenueSelects = this.listRevenueSelects.filter(
          (item) => item.id !== revenue.id
        )
      }
    }
  },
  watch: {
    data(newValue) {
      this.listRevenue = newValue
      this.listRevenueSelects = newValue
    },
    listRevenueSelects() {
      if (this.listRevenueSelects.length < 2) {
        this.$emit('openAlter', false)
      } else {
        this.$emit('openAlter', true)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'Setting/user',
      getOpenModalAlter: 'Finance/getOpenModalAlter'
    })
  },
  mounted() {
    this.listRevenue = this.data
  }
}
</script>
<style scoped>
.table {
  width: 100%;
  overflow-y: auto;
  padding: 0;
  width: 100%;
  height: 400px;
}

.headerTable {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 8px 10px;
  background-color: #089bab;
  color: white;
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 0.1fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

li {
  display: grid;
  grid-template-columns: 0.1fr 11fr;
  gap: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgb(184, 180, 180);
  padding: 24px 10px;
  background-color: #fcfeff;
  color: #5b5c5f;
  width: 100%;
}

.box-itens {
  display: grid;
  gap: 10px;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr 1fr;
  align-items: center;
  justify-content: center;
}

li.hovered {
  cursor: pointer;
  border-bottom: 1px solid #089bab;
  padding: 20px 10px;
  background-color: white;
  color: rgb(255, 255, 255);
  width: 100%;
}

.statusButton {
  margin-bottom: 6px;
  gap: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(64, 163, 239);
  font-size: 0.7rem;
  padding: 0.2rem 0.1rem;
  border-radius: 10px;
  border: none;
}

.box-buttons-status {
  display: flex;
  gap: 10px;
  align-items: center;
}

.checkbox-item {
  border: 1px solid #fcfeff;
  border-radius: 2px;
}
.box-button {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.att {
  gap: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(64, 163, 239);
  font-size: 0.9rem;
  padding: 0.4rem;
  border-radius: 10px;
  border: none;
}
.box-list-cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.list-card {
  cursor: pointer;
  padding: 10px;
  background-color: #fcfeff;
  border: 1px solid rgb(184, 180, 180);
  border-radius: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
}

.box-buttons-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.box-info-card {
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

@media (max-width: 1110px) {
  .recebided {
    display: none;
  }

  .discont {
    display: none;
  }

  .typePay {
    display: none;
  }

  .saldo {
    display: none;
  }

  .headerTable {
    grid-template-columns: 0.1fr 2fr 2fr 1fr 1fr 1fr 1fr 2fr 1fr;
    gap: 10px;
  }
  .box-itens {
    gap: 10px;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 2fr 1fr;
  }
}
@media (max-width: 810px) {
  .headerTable {
    font-size: 0.64rem;
  }
  .box-itens {
    font-size: 0.64rem;
  }
}

@media (max-width: 444px) {
  .box-info-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

@media (max-width: 390px) {
  .statusButton {
    font-size: 0.6rem;
  }
}
</style>
