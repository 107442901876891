<template>
  <b-modal
    :id="`prescription_to_remove_modal`"
    size="lg"
    title="Remover medicamentos"
    header-bg-variant="primary"
    title-class="text-light"
    label-for="observation-input"
  >
    <h3 class="warning-text">Atenção!</h3>

    <h4 class="warning-text">
      Ao remover o medicamento escolhido, todos os medicamentos inseridos na
      mesma prescrição também serão removidos.
    </h4>

    <br />

    <h4 class="warning-text">Esta ação não poderá ser desfeita.</h4>

    <br />
    <h4 class="warning-text">
      Confira abaixo os medicamentos a serem removidos:
    </h4>

    <br />
    <br />
    <br />

    <table class="table">
      <thead>
        <tr>
          <th>Medicamento/Material</th>
          <th>Qtd</th>
          <th>Posologia</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="prescription in prescriptionsToRemove"
          :key="prescription.id"
        >
          <td>{{ prescription.desMedicamento }}</td>

          <td>{{ prescription.quantidade }}</td>

          <td>{{ stripHtmlTags(prescription.posologia) }}</td>
        </tr>
      </tbody>
    </table>

    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel()">
        Cancelar
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        @click="$emit('removePrescriptions')"
      >
        Remover
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { removeTagsFromString } from '../../../../../Utils/formatters'
export default {
  name: 'RemovePrescriptionsModal',
  props: ['prescriptionsToRemove'],
  methods: {
    removeTagsFromStrings(string) {
      const text = removeTagsFromString(string)

      return text
    },

    stripHtmlTags(input) {
      if (!input) return '' // Garante que valores nulos ou indefinidos retornem uma string vazia
      return input.replace(/<[^>]*>/g, '') // Remove todas as tags HTML
    }
  }
}
</script>

<style scoped lang="scss">
.warning-text {
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

th {
  background-color: #257f9d;
  color: #fff;
}

th:nth-child(1),
td:nth-child(1) {
  width: 40%;
  text-align: center;
}

th:nth-child(2),
td:nth-child(2) {
  width: 10%;
  text-align: center;
}

th:nth-child(3),
td:nth-child(3) {
  width: 40%;
  text-align: center;
}
</style>
