<template>
  <b-modal
    id="add-exam-lab"
    size="lg"
    title="Adicione um novo exame"
    header-bg-variant="primary"
    title-class="text-light"
    cancel-title="Cancelar"
    cancel-variant="danger"
    ok-title="Salvar"
    @hide="resetModal"
    @ok="addExam()"
    @close="resetModal"
    @cancel="resetModal"
  >
    <!-- Modal de adicionar -->
    <div class="add-exam">
      <div class="search-exam">
        <input
          class="input-exam"
          type="text"
          placeholder="Busque aqui por um exame"
          v-model="nameFilter"
          @input="searchService($event)"
          @keyup.enter="searchService($event)"
        />
        <button @click="searchService(nameFilter)">
          <i v-if="loading === false" class="fas fa-search"></i>
          <b-spinner
            v-else
            class="alignCenter"
            small
            variant="primary"
          ></b-spinner>
        </button>
      </div>
      <div class="exams-list" v-if="showResults && listExams.length > 0">
        <div v-if="examSelected === null">
          <li
            @click="selectedExam(exam)"
            class="exam-option"
            v-for="(exam, index) in listExams"
            :key="index"
          >
            <h5>{{ exam.codigoServico }} - {{ exam.descServico }}</h5>
          </li>
        </div>
        <div v-else>
          <li class="exam-option" :key="index">
            <h5>
              {{ examSelected.codigoServico }} - {{ examSelected.descServico }}
            </h5>
          </li>
        </div>
      </div>
      <div class="detail-exam" v-else>
        <h4>Busque por algum serviços!!</h4>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions } from 'vuex'
import API from '../../../../../../../services/index'
export default {
  name: 'exam-modal',
  props: ['examList', 'idPreNatal', 'idProntuario'],
  data() {
    return {
      authToken: {
        headers: { Authorization: `Bearer ${window.$cookies.get('token')}` }
      },
      nameFilter: '',
      listExams: [],
      examDetail: {},
      examSelected: null,
      showResults: false,
      loading: false,

      // Nova vacina pra adicionar no stado global
      exam: {
        idProntuario: this.idProntuario,
        idPreNatal: this.idPreNatal,
        dtInclusao: new Date(),
        dtOcorrencia: new Date(),
        descExamePreNatal: '',
        codigoExamesTuss: null,
        ativo: 0,
        listaResultado: [
          {
            dataResultado: '',
            resultado: '',
            codigoExamesTuss: null,
            sequencial: 0,
            descArquivo: {
              nomeArquivo: '',
              arquivo: null,
              urlArquivo: null
            }
          },
          {
            dataResultado: '',
            resultado: '',
            codigoExamesTuss: null,
            sequencial: 1,
            descArquivo: {
              nomeArquivo: '',
              arquivo: null,
              urlArquivo: null
            }
          },
          {
            dataResultado: '',
            resultado: '',
            codigoExamesTuss: null,
            sequencial: 2,
            descArquivo: {
              nomeArquivo: '',
              arquivo: null,
              urlArquivo: null
            }
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      addNewExamLaboratoryAction: 'MedicalReports/addNewExamLaboratoryAction'
    }),
    async searchService(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        const filter = event.target.value
        if (!filter) {
          this.showResults = false
        } else {
          this.loading = true
          API.get(
            '/api/Servico/BuscaPorServico/',
            {
              params: {
                busca_servico: filter.toUpperCase(),
                id_clinica: 0,
                tipo_servico: 'PROC'
              }
            },
            this.authToken
          )
            .then((res) => {
              if (res.data.object.length === 0) {
                this.showResults = false
                this.$toast.error('Nenhum serviço encontrado!')
              } else {
                this.listExams = res.data.object
                this.showResults = true
              }
              this.loading = false
            })
            // eslint-disable-next-line handle-callback-err
            .catch(() => {
              this.showResults = false

              this.loading = false
            })
        }
      }, 1000)
    },
    // Reseta o modal
    resetModal() {
      this.$bvModal.hide('add-exam')
      this.$emit('closeModal')
    },
    // Add exam na lista global
    addExam() {
      this.addNewExamLaboratoryAction(this.exam)
      this.$emit('closeModal')
    },
    // Captura os dados do exame especificos para mostra nos detalhes
    selectedExam(exam) {
      this.examSelected = {
        ...exam
      }
      this.exam.descExamePreNatal = this.examSelected.descServico
      this.exam.codigoExamesTuss = this.examSelected.codigoServico
      this.exam.listaResultado[0].codigoExamesTuss =
        this.examSelected.codigoServico
      this.exam.listaResultado[1].codigoExamesTuss =
        this.examSelected.codigoServico
      this.exam.listaResultado[2].codigoExamesTuss =
        this.examSelected.codigoServico
    }
  },
  mounted() {
    this.$bvModal.show('add-exam-lab')
  }
}
</script>
<style scoped>
.exams-list {
  padding: 10px;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  overflow-y: scroll;
  gap: 10px;
}

.search-exam {
  position: relative;
}

.search-exam button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 37%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.input-exam {
  margin-bottom: 20px;
  padding: 12px;
  padding-right: 40px;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: #c3c4c7 solid 1px;
}

.input-exam:focus {
  outline: none;
  border: #0cb1c4 solid 1px;
}

.exam-option {
  list-style: none;
  padding: 10px;
  border-radius: 8px;
  background-color: #edf6f7;
  color: #c3c4c7;
  cursor: pointer;
  transition: all 400ms ease;
}

.exam-option:hover {
  background-color: #0cb1c4;
}

.exam-option:active {
  background-color: #022d32;
}

.detail-exam {
  border: solid 0.5px #c3c4c7;
  background-color: #edf6f7;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.detail-exam h1 {
  font-size: 1.6rem;
}
</style>
